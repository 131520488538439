@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
* {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
}
.navigation {
  position: sticky;
  width: 100vw;
  justify-content: flex-end;
  background-color: #faf8dd;
  /* border: 1px solid black; */
  height: 60px;
  display: flex;
  align-items: center;
  list-style: none;
}
li :link,
:visited {
  font-size: 18px;
  margin: 60px;
  padding: 10px;
  text-decoration: none;
  color: black;
}
@media all and (min-width: 200px) and (max-width: 480px) {
  li :link,
  :visited {
    font-size: 13px;
    padding: 0;
    margin: 4px;
    text-decoration: none;
    color: black;
  }
}
li :hover {
  color: #d42a2a;
}

.logo {
  left: 2%;
  top: 12%;
  position: absolute;
}
