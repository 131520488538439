.main {
  /* border: 1px solid rgb(5, 4, 4); */
  width: 100vw;
  height: 90vh;
  display: flex;
}

.left {
  /* border: 2px solid black; */
  width: 25vw;
  height: 80vh;
  align-items: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: auto;
  margin-top: 60px;
}

.right {
  /* border: 2px solid black; */
  width: 50vw;
  height: 80vh;
  margin-right: auto;
  margin-top: 60px;
  display: inline-flex;
  flex-direction: column;
  padding-top: 50px;
}
.self {
  border-radius: 50%;
}
#name {
  text-align: center;
}
#social {
  margin-top: 10px;
  width: 40px;
  height: 40px;
  flex-direction: row;
}
#desc {
  text-align: center;
}
.aboutme {
  font-size: 35px;
  background: linear-gradient(
    120deg,
    rgb(9, 54, 54) 2.69%,
    purple 20.19%,
    red 63.69%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.aboutp {
  padding-right: 50px;
  line-height: 30px;
  font-weight: 30;
}
.skills {
  font-size: 35px;
  background: linear-gradient(
    120deg,
    rgb(9, 54, 54) 2.69%,
    purple 20.19%,
    red 63.69%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.skillsp {
  display: block;
  gap: 10;
  width: 80%;
}
@media all and (min-width: 200px) and (max-width: 480px) {
  .main {
    display: block;
    flex-direction: column;
    justify-content: center;
  }
  .left {
    align-items: center;
    height: auto;
    width: auto;
  }
  .right {
    text-align: center;
    display: block;
    margin-top: 0;
    height: auto;
    width: auto;
  }
  .aboutp {
    text-align: center;
    padding: 15px;
  }
  .skillsp {
    margin: 0 auto;
    gap: 20px;
  }
}
