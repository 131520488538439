.hero {
  width: 100%;
  height: 90vh;
  background: url(../../public/images/background1.png);
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position: 100%; */
}
.content {
  position: absolute;
  top: 25%;
  left: 8%;
}
.content .title {
  color: #1f1f25;
  font-size: 40px;
  text-transform: uppercase;
  letter-spacing: 4px;
  display: inline-block;
  margin-bottom: 20px;
  background: linear-gradient(
    120deg,
    rgb(9, 54, 54) 20.69%,
    purple 50.19%,
    red 79.69%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.content h1 {
  color: #1f1f25;
  font-size: 110px;
  font-weight: 900;
  line-height: 90px;

  width: 100%;
}
.content h1 span {
  color: red;
}

.content p {
  width: 45%;
  color: #1f1f1f;
  margin-top: 20px;
  margin-bottom: 20px;
}
@media all and (min-width: 200px) and (max-width: 480px) {
  .content .title {
    font-size: 20px;
    position: absolute;
    bottom: 113%;
  }
  .content h1 {
    color: #1f1f25;
    font-size: 60px;
    font-weight: 900;
    line-height: 90px;
    width: 50%;
  }
  .hero {
    width: 100%;
    height: 100vh;
    background: url(../../public/images/background1.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 68%;
  }
}
